import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import MapboxDirections from "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions";
import "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css";
import polyline from "@mapbox/polyline";
import style from './directionsStyles';
import './Map.css'; // Import the external CSS file
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton, Tabs, Tab, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SaveIcon from '@mui/icons-material/Save';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import LocationDisabledIcon from '@mui/icons-material/LocationDisabled';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DeleteIcon from '@mui/icons-material/Delete';

import TabsComponent  from './TabsComponent'; // Import Tabs component from TabsComponent.js

const MARKER_COLOR_DEFAULT = "#1565c0"; // Blue color for default markers
const MARKER_COLOR_SELECTED = "#b71c1c"; // Red color for selected markers
const MILESTONE_MARKER_COLOR = "#ffd700"; // Yellow color for milestone markers
const USER_LOCATION_MARKER_COLOR = "red"; // Color for user location marker

mapboxgl.accessToken =
  "pk.eyJ1Ijoicm1hcGRldiIsImEiOiJjbTIzM3pjcHUwMXM0MmtzNjBvYWVoYjBwIn0.8HWOwE3EwFXu7fTewVXcOQ";



const Map = () => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null); // Ref for the map instance
  const [points, setPoints] = useState([]);
  const [totalDistance, setTotalDistance] = useState(0); // State to store total distance
  const markersRef = useRef([]);
  const directionsRef = useRef(null);
  const pointsRef = useRef([]); // Ref to keep track of points
  const routeGeoJsonRef = useRef(null); // Ref to keep track of the route GeoJSON
  const lastDraggedMarkerRef = useRef(null); // Ref to track the last dragged marker
  const [showSaveOverlay, setShowSaveOverlay] = useState(false); // State to manage save/load overlay visibility
  const [saveData, setSaveData] = useState(null); // State to store the save data
  const [tabValue, setTabValue] = useState(0); // State to manage the selected tab
  const milestoneMarkersRef = useRef([]); // Ref to keep track of milestone markers
  const [userLocationOn, setUserLocationOn] = useState(false); // State to manage user location visibility
  const userLocationMarkerRef = useRef(null); // Ref to store user location marker
  const userLocationWatcherRef = useRef(null); // Ref to store the location watcher ID
  const [jsonInput, setJsonInput] = useState(""); // State for JSON input
  const [lockMarkers, setLockMarkers] = useState(false); // State to manage marker lock
  const lockMarkersRef = useRef(false); // Using useRef instead of useState
  const [isPointsLoadedFromLocalStorage, setIsPointsLoadedFromLocalStorage] = useState(false); // New state to track if points have been loaded from localStorage
  const deviceDirectionRef = useRef(0); // Ref to store the compass direction


  const [userSpeed, setUserSpeed] = useState(0); // State to store user speed
  const [userHeading, setUserHeading] = useState(0); // State to store user heading
  const [userDirection, setUserDirection] = useState("N"); // State to store user compass direction

  const [onlineRecords, setOnlineRecords] = useState([]);

  const routeLegsRef = useRef(null); // Ref to store route legs
  const previousHeadingRef = useRef(0); 


  // Keydown handler to remove the last point when backspace is pressed or redraw route on space
  const handleKeyDown = (e) => {
    if (lockMarkersRef.current) return; // Prevent operations if markers are locked

    if (e.key === "Backspace" || e.code === "Backspace") {
      e.preventDefault(); // Prevent default backspace behavior (e.g., navigating back)
      handleDeleteSelectedMarker(); // Call the refactored delete function
    } else if (e.key === " " || e.code === "Space") {
      e.preventDefault(); // Prevent default spacebar behavior
      redrawRoute(); // Redraw the route when space is pressed
    }
  };


  const handleDeleteSelectedMarker = () => {
    if (lockMarkersRef.current || !lastDraggedMarkerRef.current) return; // Prevent operation if markers are locked or no marker selected

    const markerIndex = markersRef.current.indexOf(lastDraggedMarkerRef.current);
    if (markerIndex !== -1) {
      // Remove the marker from the map
      markersRef.current[markerIndex].remove();
      markersRef.current.splice(markerIndex, 1);

      // Update points array by removing the corresponding point
      const updatedPoints = pointsRef.current.filter((_, index) => index !== markerIndex);
      pointsRef.current = updatedPoints;
      setPoints(updatedPoints);

      // Reset the selected marker to the last marker in the array or null if no markers are left
      if (markersRef.current.length > 0) {
        lastDraggedMarkerRef.current = markersRef.current[markersRef.current.length - 1];
        lastDraggedMarkerRef.current.getElement().style.backgroundColor = MARKER_COLOR_SELECTED; // Select the last marker
      } else {
        lastDraggedMarkerRef.current = null;
      }

      // Update marker labels and redraw the route
      // updateMarkerLabels();
      // redrawRoute();
    }
  };


  // Initialize map when component mounts
  useEffect(() => {
    
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [114.17, 22.32], // Longitude and Latitude of Kowloon, Hong Kong
      zoom: 14,
      localIdeographFontFamily: "sans-serif", // Support for Chinese characters
    });

    map.on("styledata", () => {
      const layers = map.getStyle().layers;

      // Update the text-field language for layers supporting symbols
      layers.forEach((layer) => {
        if (layer.type === "symbol" && layer.layout && layer.layout["text-field"]) {
          map.setLayoutProperty(layer.id, "text-field", [
            "coalesce",
            ["get", `name_zh`], // Use Chinese language field
            ["get", "name"] // Fallback to default language
          ]);
        }
      });
    });


    // Add navigation control (the +/- zoom buttons)
    // map.addControl(new mapboxgl.NavigationControl(), "top-right");

    // Store map instance in ref
    mapRef.current = map;

    // Initialize directions control once
    const directions = new MapboxDirections({
      accessToken: mapboxgl.accessToken,
      profile: "mapbox/walking", // Specify walking profile
      unit: "metric",
      controls: {
        inputs: false, // Hide input fields
        instructions: false, // Hide instructions
      },
      flyTo: false, // Disable flyTo behavior
      styles: style,
      interactive: false,
    });


    directionsRef.current = directions;

    // Add Directions control to the map
    map.addControl(directions, "top-left");

    // Load saved points from local storage if they exist
  map.on("load", (e) => {
    resetPoints(); 
    const savedPoints = localStorage.getItem('savedPoints');
    if (savedPoints) {
      try {
        const loadedPoints = JSON.parse(savedPoints);
        if (Array.isArray(loadedPoints) && loadedPoints.length > 0) {
          // Load the saved points and add markers
          loadedPoints.forEach((point, index) => {
            addMarker(point, MARKER_COLOR_DEFAULT, index);
          });

          enableMarkerLockIfNotLocked();
        }
      } catch (error) {
        console.error('Failed to load points from localStorage:', error);
      }
    }
    setIsPointsLoadedFromLocalStorage(true); // Set to true once points have been loaded from localStorage
  });

    // Click handler to add markers or add to route
    map.on("click", (e) => {
      console.log(lockMarkersRef.current )
      if (lockMarkersRef.current ) return; // Prevent adding markers if markers are locked
      
      const clickedPoint = [e.lngLat.lng, e.lngLat.lat];
      console.log(clickedPoint);

      // Check if click is close to an existing marker
      const thresholdDistance = 0.05; // Approximate distance in degrees (tune this as needed)
      let selectedMarker = null;
      markersRef.current.forEach((marker, index) => {
        const markerLngLat = marker.getLngLat();
        const distance = haversineDistance(clickedPoint, [markerLngLat.lng, markerLngLat.lat]);
        if (distance < thresholdDistance) {
          selectedMarker = marker;
        }
      });

      if (selectedMarker) {
        // Set the selected marker as red
        if (lastDraggedMarkerRef.current) {
          lastDraggedMarkerRef.current.getElement().style.backgroundColor = MARKER_COLOR_DEFAULT; // Reset previous marker color
        }
        selectedMarker.getElement().style.backgroundColor = MARKER_COLOR_SELECTED;
        lastDraggedMarkerRef.current = selectedMarker;
        return;
      }

      if (pointsRef.current.length >= 25) {
        alert("You can only add up to 25 points.");
        return;
      }

      // Check if clicked point is close to the route GeoJSON
      if (routeGeoJsonRef.current) {
        const routeCoordinates = routeGeoJsonRef.current.geometry.coordinates;
        let closestPoint = null;
        let closestIndex = -1;
        let closestDistance = Infinity;

        // Find the closest point on the route
        for (let i = 0; i < routeCoordinates.length; i++) {
          const [lng, lat] = routeCoordinates[i];
          const distance = haversineDistance(clickedPoint, [lng, lat]);

          // console.log("distance",distance);
          if (distance < closestDistance && distance < thresholdDistance) {
            closestPoint = [lng, lat];
            closestDistance = distance;
          }
        }

        // Find the closest segment to the clicked point
        if (closestPoint) {
          let segmentClosestIndex = -1;
          closestDistance = Infinity;

          console.log("closestPoint", pointsRef.current);
          for (let i = 0; i < pointsRef.current.length - 1; i++) {
            const pointA = pointsRef.current[i];
            const pointB = pointsRef.current[i + 1];

            const distanceToSegment = calculateDistanceToSegment(clickedPoint, pointA, pointB);
            console.log(distanceToSegment);

            if (distanceToSegment < closestDistance) {
              closestDistance = distanceToSegment;
              segmentClosestIndex = i + 1;
            }
          }

          console.log(segmentClosestIndex);

          if (segmentClosestIndex !== -1) {
            closestIndex = segmentClosestIndex;
          }

          console.log("Adding marker to closest point on route:", closestPoint);
          addMarker(closestPoint, "green", closestIndex);
          return;
        }
      }

      // Add a marker for the clicked point if not close to route
      addMarker(clickedPoint, MARKER_COLOR_DEFAULT);

    });

    window.addEventListener("keydown", handleKeyDown);

    // Clean up on unmount
    return () => {
      map.remove();
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const resetPoints = () => {
    // Remove all markers
    markersRef.current.forEach(marker => marker.remove());
    markersRef.current = [];

    // Clear the route if one exists
    if (directionsRef.current) {
      directionsRef.current.removeRoutes();
    }

    // Reset routeGeoJsonRef
    routeGeoJsonRef.current = null;

    // Clear pointsRef and set empty points
    pointsRef.current = [];
    setPoints([]);

    // Remove milestone markers
    milestoneMarkersRef.current.forEach(marker => marker.remove());
    milestoneMarkersRef.current = [];

    setShowSaveOverlay(false); // Close the dialog after reset
  };

  // Effect to handle drawing the walking route between all points
const redrawRoute = () => {
  if (pointsRef.current.length < 2) {
    // If there are less than 2 points, clear the existing route
    if (directionsRef.current) {
      directionsRef.current.removeRoutes(); // Remove the route from the directions control
    }
    routeGeoJsonRef.current = null; // Reset the GeoJSON reference
    setTotalDistance(0); // Reset total distance to 0
    return; // Exit early as there are not enough points to draw a route
  }

  if (directionsRef.current && mapRef.current) {
    const directions = directionsRef.current;
    directions.removeRoutes();
    
    // Temporarily disable map movement methods to avoid flyTo behavior
    const map = mapRef.current;
    const originalFitBounds = map.fitBounds;
    const originalFlyTo = map.flyTo;
    map.fitBounds = () => {}; // No-op to prevent movement (disable centering)
    map.flyTo = () => {}; // No-op to prevent movement (disable zoom and centering)

    // Set origin, waypoints, and destination
    directions.setOrigin(pointsRef.current[0]);

    if (pointsRef.current.length > 2) {
      const waypoints = pointsRef.current.slice(1, pointsRef.current.length - 1);
      waypoints.forEach((point, index) => directions.addWaypoint(index, point));
    }

    directions.setDestination(pointsRef.current[pointsRef.current.length - 1]);

    // Calculate and set total distance, and log geometry coordinates
    directions.on('route', (e) => {
      if (e.route && e.route.length > 0) {
        const distance = e.route[0].distance / 1000; // Convert to kilometers
        setTotalDistance(distance.toFixed(2));
		
		routeLegsRef.current = e.route[0].legs; 

        // Decode polyline string to GeoJSON coordinates
        const decodedCoordinates = polyline.decode(e.route[0].geometry);
        const geojson = {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: decodedCoordinates.map(([lat, lng]) => [lng, lat]),
          },
          properties: {},
        };
        routeGeoJsonRef.current = geojson;

        // Remove existing milestone markers
        milestoneMarkersRef.current.forEach(marker => marker.remove());
        milestoneMarkersRef.current = [];

        // Add milestone markers every 1 km using Haversine formula
        let accumulatedDistance = 0;
        let lastMilestonePoint = routeGeoJsonRef.current.geometry.coordinates[0];
        let kmCount = 1;

        for (let i = 1; i < routeGeoJsonRef.current.geometry.coordinates.length; i++) {
          const [lng1, lat1] = lastMilestonePoint;
          const [lng2, lat2] = routeGeoJsonRef.current.geometry.coordinates[i];
          const segmentDistance = haversineDistance([lng1, lat1], [lng2, lat2]);

          accumulatedDistance += segmentDistance;

          // Place milestone markers for each full kilometer reached
          while (accumulatedDistance >= 1) {
            // Calculate the ratio needed to place the milestone exactly 1 km from the last milestone
            const ratio = (1 - (accumulatedDistance - segmentDistance)) / segmentDistance;

            // Linear interpolation to find the milestone point
            const milestoneLng = lng1 + ratio * (lng2 - lng1);
            const milestoneLat = lat1 + ratio * (lat2 - lat1);

            // Create and add the milestone marker to the map
            const milestoneMarker = new mapboxgl.Marker({
              element: createMilestoneMarker(kmCount),
            })
              .setLngLat([milestoneLng, milestoneLat])
              .addTo(mapRef.current);
            milestoneMarkersRef.current.push(milestoneMarker);

            // Update lastMilestonePoint to the new milestone point
            lastMilestonePoint = [milestoneLng, milestoneLat];

            // Decrease accumulatedDistance by 1 km, since we've placed a milestone
            accumulatedDistance -= 1;

            // Increment the kilometer count for the next milestone
            kmCount++;
          }

          // Update lastMilestonePoint to the current point for the next iteration
          lastMilestonePoint = [lng2, lat2];
        }
      }
    });

    // Restore original methods after route setup
    setTimeout(() => {
      map.fitBounds = originalFitBounds;
      map.flyTo = originalFlyTo;
    }, 1000);
  }
};

const savePointsToLocalStorage = () => {
  const pointsToSave = pointsRef.current;

  // Trace the points being saved
  // console.log("Saving points to localStorage:", pointsToSave);

  try {
    localStorage.setItem('savedPoints', JSON.stringify(pointsToSave));

    // Confirm successful saving
    // console.log("Points successfully saved to localStorage");
  } catch (error) {
    // Log any errors during the saving process
    console.error("Error saving points to localStorage:", error);
  }

  // Optionally, you can log what was actually saved in localStorage for further verification
  const savedData = localStorage.getItem('savedPoints');
  // console.log("Data currently in localStorage:", savedData);
};


useEffect(() => {
  // Only save points if the points have been loaded from localStorage (i.e., not on initial render)
  if (isPointsLoadedFromLocalStorage) {
    setTimeout(() => {
      savePointsToLocalStorage();
      redrawRoute();
    }, 200);
  }
}, [points, isPointsLoadedFromLocalStorage]); // Add isPointsLoadedFromLocalStorage as a dependency


  // Function to add a marker
const addMarker = (point, color, insertIndex = pointsRef.current.length) => {
  // Create a new marker
  const newMarker = new mapboxgl.Marker({
    color: color,
    element: createCustomMarker(getLabelFromIndex(insertIndex), color),
    draggable: !lockMarkersRef.current , // Set draggable based on lockMarkers state
  })
    .setLngLat(point)
    .addTo(mapRef.current);

  // Add event listener for drag end to update points
  newMarker.on('dragend', () => {
    if (lockMarkersRef.current ) return; // Prevent updating if markers are locked

    // Temporarily disable map movement methods to avoid flyTo behavior
    const map = mapRef.current;
    const originalFitBounds = map.fitBounds;
    const originalFlyTo = map.flyTo;
    map.fitBounds = () => {}; // No-op to prevent movement (disable centering)
    map.flyTo = () => {}; // No-op to prevent movement (disable zoom and centering)

    const newLngLat = newMarker.getLngLat();
    const newPoints = pointsRef.current.map((p, index) =>
      index === markersRef.current.indexOf(newMarker) ? [newLngLat.lng, newLngLat.lat] : p
    );
    pointsRef.current = newPoints;
    setPoints(newPoints);

    // Set the last dragged marker as red
    if (lastDraggedMarkerRef.current) {
      lastDraggedMarkerRef.current.getElement().style.backgroundColor = MARKER_COLOR_DEFAULT; // Reset previous marker color
    }
    newMarker.getElement().style.backgroundColor = MARKER_COLOR_SELECTED;
    lastDraggedMarkerRef.current = newMarker;

    // Restore original methods after updating points
    setTimeout(() => {
      map.fitBounds = originalFitBounds;
      map.flyTo = originalFlyTo;
    }, 1000);
  });

  // Automatically select the new marker
  if (lastDraggedMarkerRef.current) {
    lastDraggedMarkerRef.current.getElement().style.backgroundColor = MARKER_COLOR_DEFAULT; // Reset previous marker color
  }
  newMarker.getElement().style.backgroundColor = MARKER_COLOR_SELECTED;
  lastDraggedMarkerRef.current = newMarker;

  // Add the marker to the markers array
  markersRef.current.splice(insertIndex, 0, newMarker);
  setPoints((prevPoints) => {
    const newPoints = [...prevPoints.slice(0, insertIndex), point, ...prevPoints.slice(insertIndex)];
    pointsRef.current = newPoints; // Update ref with new points
    updateMarkerLabels();
    return newPoints;
  });
};

// Function to toggle marker lock

  const toggleMarkerLock = () => {
    // Update the lockMarkers state
    setLockMarkers((prevLock) => {
      const newLockState = !prevLock;

      lockMarkersRef.current = !lockMarkersRef.current;

    markersRef.current.forEach((marker) => {
      marker.setDraggable(!lockMarkersRef.current); // Set draggable property based on new lock state
    });
      return newLockState; // Return the updated state
    });
  };

  const enableMarkerLockIfNotLocked = () => {
    // Check if markers are already locked
    if (lockMarkersRef.current) {
      console.log("Markers are already locked, doing nothing.");
      return; // Do nothing if markers are already locked
    }

    // If markers are not locked, call toggleMarkerLock to lock them
    console.log("Markers are not locked, enabling lock.");
    toggleMarkerLock();
  };

  useEffect(() => {
    lockMarkersRef.current = lockMarkers; // Ensure ref is in sync with state
  }, [lockMarkers]); // Every time lockMarkers changes, update the ref

  // Function to create a custom marker with a label
  const createCustomMarker = (label, color) => {
    const markerElement = document.createElement("div");
    markerElement.className = "custom-marker";
    markerElement.style.backgroundColor = color;
    markerElement.style.width = "14px";
    markerElement.style.height = "14px";
    markerElement.style.borderRadius = "50%";
    markerElement.style.display = "flex";
    markerElement.style.alignItems = "center";
    markerElement.style.justifyContent = "center";
    markerElement.style.color = "white";
    markerElement.style.fontWeight = "bold";
    markerElement.textContent = label;
    return markerElement;
  };

  // Function to create a milestone marker
  const createMilestoneMarker = (km) => {
    const milestoneElement = document.createElement("div");
    milestoneElement.className = "milestone-marker";
    milestoneElement.style.backgroundColor = MILESTONE_MARKER_COLOR; // Darker yellow
    milestoneElement.style.width = "16px";
    milestoneElement.style.height = "16px";
    milestoneElement.style.borderRadius = "0"; // Make it a square
    milestoneElement.style.display = "flex";
    milestoneElement.style.alignItems = "center";
    milestoneElement.style.justifyContent = "center";
    milestoneElement.style.color = "black";
    milestoneElement.style.fontWeight = "bold";
    milestoneElement.textContent = `${km}`;
    return milestoneElement;
  };

  // Function to update marker labels after reordering
  const updateMarkerLabels = () => {
    markersRef.current.forEach((marker, index) => {
      const markerElement = marker.getElement();
      markerElement.textContent = getLabelFromIndex(index);
    });
  };

  // Function to convert an index to a label (A-Z, AA, AB, ...)
const getLabelFromIndex = (index) => {
  let label = '';
  index++; // Increment index to match how humans count (1-based index for labels)
  
  while (index > 0) {
    index--; // Decrement index to handle zero-based index logic
    label = String.fromCharCode(65 + (index % 26)) + label;
    index = Math.floor(index / 26);
  }
  
  return label;
};

  // Function to calculate distance between two points using Haversine formula
const haversineDistance = (pointA, pointB) => {
  const [lngA, latA] = pointA;
  const [lngB, latB] = pointB;

  // Radius values (in km) for more accurate distance calculation
  const R_EQUATORIAL = 6378.137; // Radius of the Earth at the equator in km
  const R_POLAR = 6356.752; // Radius of the Earth at the poles in km
  const R_AVERAGE = 6371.0088; // Average radius of the Earth in km (most commonly used)

  // Use the average radius of Earth for general purposes
  const R = R_AVERAGE;

  const dLat = (latB - latA) * (Math.PI / 180);
  const dLng = (lngB - lngA) * (Math.PI / 180);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(latA * (Math.PI / 180)) *
    Math.cos(latB * (Math.PI / 180)) *
    Math.sin(dLng / 2) *
    Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // Distance in km
};


  // Function to calculate the distance from a point to a segment defined by two points
  const calculateDistanceToSegment = (point, pointA, pointB) => {
    const [lngA, latA] = pointA;
    const [lngB, latB] = pointB;
    const [lng, lat] = point;

    const A = lng - lngA;
    const B = lat - latA;
    const C = lngB - lngA;
    const D = latB - latA;

    const dot = A * C + B * D;
    const len_sq = C * C + D * D;
    let param = -1;
    if (len_sq !== 0) param = dot / len_sq;

    let closestLng, closestLat;

    if (param < 0) {
      closestLng = lngA;
      closestLat = latA;
    } else if (param > 1) {
      closestLng = lngB;
      closestLat = latB;
    } else {
      closestLng = lngA + param * C;
      closestLat = latA + param * D;
    }

    return haversineDistance(point, [closestLng, closestLat]);
  };

  // Function to handle save button click
  const handleSave = () => {
    const jsonPoints = JSON.stringify(pointsRef.current);
    console.log(pointsRef.current)
    setSaveData(jsonPoints);
    setShowSaveOverlay(true);
  };

// Function to handle load button click
const handleLoad = () => {
  try {
    const loadedPoints = JSON.parse(jsonInput); // Use state variable jsonInput

    if (Array.isArray(loadedPoints)) {
      // Clear existing markers from the map
      markersRef.current.forEach(marker => marker.remove());
      markersRef.current = [];

      // Clear the route if one exists
      if (directionsRef.current) {
        directionsRef.current.removeRoutes();
      }

      // Reset routeGeoJsonRef
      routeGeoJsonRef.current = null;

      // Clear pointsRef and set new points
      pointsRef.current = loadedPoints;
      setPoints([]);

      // Add the loaded points to the map as new markers
      loadedPoints.forEach((point, index) => {
        addMarker(point, MARKER_COLOR_DEFAULT, index);
      });

      // Redraw the route with the newly loaded points
      redrawRoute();

      // Close the popup dialog after successful load
      setShowSaveOverlay(false);
      enableMarkerLockIfNotLocked();

    } else {
      alert("Invalid JSON format. Please provide an array of points.");
    }
  } catch (error) {
    alert("Failed to load JSON: " + error.message);
  }
};

// Function to create a custom user-location pointer with a wrapper, circle, and arrow
// Function to create a custom user-location pointer with a wrapper, circle, and arrow
const createUserLocationPointer = () => {
  const wrapperElement = document.createElement("div");
  wrapperElement.className = "user-location-wrapper"; // Wrapper for positioning

  const pointerElement = document.createElement("div");
  pointerElement.className = "user-location-pointer"; // Blue circle with white border and shadow

  // Create the SVG element for the arrow, now filled with white and slightly smaller
  const arrowSVG = `
    <svg width="14" height="14" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg" class="user-location-arrow">
      <path d="M12 2L22 22L12 17L2 22L12 2Z" stroke="none" fill="white"/>
    </svg>
  `;

  pointerElement.innerHTML = arrowSVG; // Insert the SVG inside the pointer
  wrapperElement.appendChild(pointerElement);
  return wrapperElement;
};

const updateUserLocationHeading = (newHeading) => {
  if (userLocationMarkerRef.current) {
    const arrowElement = userLocationMarkerRef.current.getElement().querySelector(".user-location-arrow");

    // Get the previous heading (initially stored or from previous state)
    let previousHeading = previousHeadingRef.current || 0;

    // If the new heading is smaller but close to 0, adjust it to avoid counter-clockwise rotation
    if (newHeading < previousHeading && Math.abs(newHeading - previousHeading) > 180) {
      newHeading += 360;
    }

    // Apply the smooth rotation
    arrowElement.style.transition = 'transform 0.5s ease-out'; // Add smooth transition
    arrowElement.style.transform = `rotate(${newHeading}deg)`;

    // Store the current heading as the previous heading for the next update
    previousHeadingRef.current = newHeading;
  }
};



// Handle device orientation (update heading from the compass)
const handleDeviceOrientation = (event) => {
  let heading = 0;

  if (event.webkitCompassHeading !== undefined) {
    // Use webkitCompassHeading for iOS devices (it provides the direction relative to the magnetic north)
    heading = event.webkitCompassHeading;

    // Check for absolute or true north compass heading, apply necessary correction
    if (event.webkitCompassAccuracy && event.webkitCompassAccuracy < 50) {
      // heading = 360 - heading; // iOS inverts the heading, so we correct it here
    }
  } else {
    // For other devices, fallback to using the alpha value (0° is north)
    heading = event.alpha || 0;
  }

  // Update the heading and direction based on the device's compass
  setUserHeading(heading);
  setUserDirection(getDirectionFromHeading(heading));

  // Update the arrow's heading in real-time if using device orientation
  updateUserLocationHeading(heading); // Rotate the arrow on the map (if applicable)
};

// Function to update the marker's position
const updateUserLocationPosition = (userLocation, speed = 0) => {
  if (userLocationMarkerRef.current) {
    userLocationMarkerRef.current.setLngLat(userLocation);
  } else {
    const userLocationElement = createUserLocationPointer();
    const userLocationMarker = new mapboxgl.Marker({
      element: userLocationElement,
    })
      .setLngLat(userLocation)
      .addTo(mapRef.current);
    userLocationMarkerRef.current = userLocationMarker;
  }

  // Always update the compass heading using the device compass
  const compassHeading = deviceDirectionRef.current;
  updateUserLocationHeading(compassHeading); // Rotate the arrow on the map based on the compass
};

// Helper function to convert heading to compass direction (e.g., N, S12E)
const getDirectionFromHeading = (heading) => {
  const directions = ["N", "NE", "E", "SE", "S", "SW", "W", "NW"];
  const index = Math.floor((heading + 22.5) / 45) % 8; // Each direction corresponds to 45 degrees
  return directions[index];
};


// Function to determine the user's heading
const determineUserHeading = (position) => {
  // Use the device's compass heading
  window.addEventListener("deviceorientation", handleDeviceOrientation);
  return deviceDirectionRef.current;
};

const requestDeviceOrientationPermission = () => {
  if (typeof DeviceOrientationEvent.requestPermission === 'function') {
    DeviceOrientationEvent.requestPermission()
      .then((permissionState) => {
        if (permissionState === 'granted') {
          window.addEventListener('deviceorientation', handleDeviceOrientation);
        }
      })
      .catch(console.error);
  } else {
    window.addEventListener('deviceorientation', handleDeviceOrientation);
  }
};

// Call this when enabling location services
const toggleUserLocation = () => {
  if (!userLocationOn) {
    if (navigator.geolocation) {
      const updateUserLocation = (position) => {
        const userLocation = [position.coords.longitude, position.coords.latitude];
        const speed = position.coords.speed ? (position.coords.speed * 3.6).toFixed(2) : 0; // Convert m/s to km/h
        
        updateUserLocationPosition(userLocation, speed);

        // Use device compass for heading (GPS heading removed)
      };

      userLocationWatcherRef.current = navigator.geolocation.watchPosition(
        updateUserLocation,
        () => {
          alert("Unable to retrieve your location");
        },
        { enableHighAccuracy: true }
      );

      setUserLocationOn(true);

      // Request permission for orientation (iOS-specific)
      requestDeviceOrientationPermission();
    } else {
      alert("Geolocation is not supported by your browser");
    }
  } else {
    if (userLocationWatcherRef.current) {
      navigator.geolocation.clearWatch(userLocationWatcherRef.current);
      userLocationWatcherRef.current = null;
    }
    if (userLocationMarkerRef.current) {
      userLocationMarkerRef.current.remove();
      userLocationMarkerRef.current = null;
    }
    window.removeEventListener("deviceorientation", handleDeviceOrientation);
    setUserLocationOn(false);
  }
};


// end location function

// Utility function to create a hash from points array (not too long)

async function createHashFromPoints(points) {
  const totalPoints = points.length;

  // Concatenate all points into a single string
  const pointsString = points.map(point => point.join(',')).join('-');

  // Combine the data (totalPoints and all points) into a single string
  const dataString = [
    String(totalPoints),
    pointsString
  ].join('-'); // Concatenate the data

  // Use TextEncoder to convert the string into a Uint8Array for hashing
  const encoder = new TextEncoder();
  const encodedData = encoder.encode(dataString);

  // Use the SubtleCrypto API to create a SHA-256 hash
  const hashBuffer = await crypto.subtle.digest('SHA-256', encodedData);

  // Convert the ArrayBuffer to a hex string
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

  // Optionally, take the first 8 characters of the hash to shorten the result
  return hashHex.slice(0, 8); // Adjust length as needed
}

/*
// Utility function to reverse geocode a point using Mapbox API and return the street name only
async function getStreetName(lng, lat) {
  const targetLng = 114.16736104222656;
  const targetLat = 22.32276596875097;
  const threshold = 0.001; // Define a threshold distance for "closeness", can be adjusted

  // Calculate the distance between the provided coordinates and the target point
  const isCloseToTarget = (Math.abs(lng - targetLng) < threshold) && (Math.abs(lat - targetLat) < threshold);

  // Check if the point is close to the specific target
  if (isCloseToTarget) {
    return "BOC"; // Override with "BOC" if close enough to the target point
  }

  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${mapboxgl.accessToken}&limit=1`;

  try {
    const response = await fetch(url);
    const data = await response.json();

    if (data.features && data.features.length > 0) {
      const feature = data.features[0];

      const street = feature.context
        ? feature.context.find((c) => c.id.startsWith("street") || c.id.startsWith("address"))
        : null;

      // Return the full street name or feature text
      if (street) {
        return street.text; // Always return the full name
      } else if (feature.text) {
        return feature.text; // Return the feature text if street is not available
      } else {
        return `${lat.toFixed(2)},${lng.toFixed(2)}`; // Fallback to coordinates
      }
    } else {
      return `${lat.toFixed(2)},${lng.toFixed(2)}`; // Fallback to coordinates
    }
  } catch (error) {
    console.error("Error fetching street name:", error);
    return `${lat.toFixed(2)},${lng.toFixed(2)}`; // Fallback in case of error
  }
}

// Helper function to load the street names from given points
const loadPlaces = async (points) => {
  try {
    const keyPoints = [
      points[0],
      points[Math.floor(points.length / 4)],
      points[Math.floor(points.length / 2)],
      points[Math.floor((3 * points.length) / 4)],
      points[points.length - 1]
    ];

    const results = await Promise.allSettled(
      keyPoints.map(async (point) => await getStreetName(point[0], point[1]))
    );

    // Filter successful results and return the names
    return results.map((result, index) => {
      if (result.status === 'fulfilled') {
        return result.value;
      } else {
        console.warn(`Failed to load street name for point ${index}:`, result.reason);
        return `Unknown Location ${index + 1}`;
      }
    });
  } catch (error) {
    console.error("Error loading places: ", error);
    return ["Unknown Location"]; // Fallback in case of error
  }
};

*/

// Helper function to remove consecutive duplicates
const removeConsecutiveDuplicates = (array) => {
  return array.filter((item, index, self) => {
    // Keep the item if it's the first occurrence or different from the previous one
    return index === 0 || item !== self[index - 1];
  });
};

const handleSaveOnline = async () => {
  try {
    // Ensure routeLegsRef is populated with route data
    if (!routeLegsRef.current || routeLegsRef.current.length === 0) {
      alert("No route data available to save.");
      return;
    }

    // Create a unique ID for the route based on the points
    const id = await createHashFromPoints(pointsRef.current); 
    const createdName = "user"; // Modify this if you want to use the actual user's name

    // Use the route legs stored in routeLegsRef to extract street names and other information
    const routeLegs = routeLegsRef.current;

    // Extract all street names and their distances from the route's steps
    const streetDistances = [];
    routeLegs.forEach((leg) => {
      leg.steps.forEach((step) => {
        if (step.name && step.distance) { 
          // Handle the case where the street name contains `;` and take only the first part
          const streetName = step.name.split(';')[0];
          streetDistances.push({ street: streetName, distance: step.distance });
        }
      });
    });

    // Function to remove the word "STREET" from a string
    const removeStreetWord = (name) => {
      return name.replace(/\b(street|STREET|Street)\b/g, '').trim(); // Case-insensitive removal
    };

    // Get all street names from streetDistances
    const allStreetNames = streetDistances.map(item => item.street);

    // Remove consecutive duplicates from the street names
    const uniqueStreetNames = removeConsecutiveDuplicates(allStreetNames);

    // Extract the first street, the last street, and sort streets by distance to find the longest one
    const firstStreet = uniqueStreetNames[0]; // Start of the route
    const lastStreet = uniqueStreetNames[uniqueStreetNames.length - 1]; // End of the route
    const sortedStreets = streetDistances.sort((a, b) => b.distance - a.distance); // Sort by distance

    // Find the longest street, ensuring it is not the same as start or end
    let longestStreet = sortedStreets[0]?.street;
    if (longestStreet === firstStreet || longestStreet === lastStreet) {
      longestStreet = sortedStreets[1]?.street; // Use the second-longest street if the longest is the same as start or end
    }

    // Remove the word "STREET" from first, longest, and last street names
    const cleanedFirstStreet = removeStreetWord(firstStreet);
    const cleanedLongestStreet = removeStreetWord(longestStreet);
    const cleanedLastStreet = removeStreetWord(lastStreet);

    // Route name: start-{longest}-end using only English characters
    const routeName = `${cleanedFirstStreet?.split(' ').slice(0, 3).join('').replace(/[^a-zA-Z]/g, '').toUpperCase()}-${cleanedLongestStreet?.split(' ').slice(0, 3).join('').replace(/[^a-zA-Z]/g, '').toUpperCase()}-${cleanedLastStreet?.split(' ').slice(0, 3).join('').replace(/[^a-zA-Z]/g, '').toUpperCase()}`;

    // Prepare the data for saving
    const saveData = {
      id,
      points: pointsRef.current, // Save points with coordinates
      name: routeName,           // Route name based on cleaned start, longest, and end streets
      places: uniqueStreetNames, // Save street names without consecutive duplicates
      distance: totalDistance,
      createdName,
    };

    // Send the data to the online API
    const response = await fetch("https://running-map-app.iching.workers.dev/save", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(saveData),
    });

    if (response.ok) {
      // Show an alert with the full concatenated route name on successful save
      alert(`${routeName} - saved successfully`);
    } else {
      alert("Failed to save points online");
    }
  } catch (error) {
    alert("Error saving points online: " + error.message);
  }
};



// Trigger fetching records when "Online Records" tab is selected
useEffect(() => {
  if (tabValue === 1) { // Assuming tabValue 1 corresponds to the "Online Records" tab
    fetchOnlineRecords();
  }
}, [tabValue]); // Fetch records every time the user switches to this tab



const filterChineseCharacters = (string) => {
  const chineseCharRegex = /[\u4e00-\u9fff]+/g; // Regex for Chinese characters (Unicode range)
  const chineseMatches = string.match(chineseCharRegex); // Match Chinese characters
  return chineseMatches ? chineseMatches.join('') : ''; // Join all Chinese matches or return an empty string
};
// Fetch the list of online records
const fetchOnlineRecords = async () => {
  try {
    // Fetch records from the backend API
    const response = await fetch('https://running-map-app.iching.workers.dev/list');
    const data = await response.json();

    // Clear existing records first
    setOnlineRecords([]);

    // Filter and load only valid records
    const validRecords = data.filter((record) => {
      // Ensure that both distance and timestamp are valid
      return record.distance && !isNaN(new Date(record.timestamp).getTime());
    }).map((record) => {
      // Filter places to show only Chinese characters
      const filteredPlaces = record.places.map(place => filterChineseCharacters(place));

      return {
        ...record,
        places: filteredPlaces.filter(Boolean), // Filter out any empty strings
      };
    });

    // Set the valid records to be displayed
    setOnlineRecords(validRecords);
  } catch (error) {
    console.error('Error fetching online records:', error);
  }
};

// Load a saved record
// Function to load points from the KV store (online)
const handleLoadOnline = async (id) => {
  try {
    const response = await fetch(`https://running-map-app.iching.workers.dev/load?id=${id}`);
    
    if (!response.ok) {
      throw new Error("Failed to load the record");
    }

    const data = await response.json(); // Parse the JSON response
    const { points } = data;

    if (Array.isArray(points) && points.length > 0) {
      // Clear existing markers from the map
      markersRef.current.forEach(marker => marker.remove());
      markersRef.current = [];

      // Clear the route if one exists
      if (directionsRef.current) {
        directionsRef.current.removeRoutes();
      }

      // Reset routeGeoJsonRef
      routeGeoJsonRef.current = null;

      // Clear pointsRef and set new points
      pointsRef.current = points;
      setPoints([]);

      // Add the loaded points to the map as new markers
      points.forEach((point, index) => {
        addMarker(point, MARKER_COLOR_DEFAULT, index);
      });

      // Redraw the route with the newly loaded points
      redrawRoute();

      // Close the popup dialog after successful load
      setShowSaveOverlay(false);
      enableMarkerLockIfNotLocked();
    } else {
      alert("No valid points found in the record.");
    }
  } catch (error) {
    alert("Error loading record: " + error.message);
  }
};

// Remove a saved record
const handleRemoveOnline = async (id) => {
  try {
    const response = await fetch(`https://running-map-app.iching.workers.dev/remove?id=${id}`, {
      method: "DELETE"
    });
    if (response.ok) {
      alert("Record removed successfully");
      fetchOnlineRecords(); // Refresh the records
    } else {
      alert("Failed to remove record");
    }
  } catch (error) {
    alert("Error removing record: " + error.message);
  }
};

// Function to handle tab change
const handleTabChange = (event, newValue) => {
  setTabValue(newValue);
};

return (
  <div>
    <div className="map-container" ref={mapContainerRef} />

    {/* Custom buttons arranged vertically on the right */}
    <div style={{
      position: 'absolute',
      top: '10px', // Adjust this value for the vertical position of the buttons
      right: '10px', // Align to the right
      display: 'flex',
      flexDirection: 'column', // Arrange buttons vertically
      gap: '10px', // Add some spacing between buttons
      zIndex: 1, // Ensure the buttons appear on top of the map
    }}>
      {/* Icon-only buttons with white background, square shape */}
      <Button
        variant="contained"
        onClick={handleSave}
        sx={{
          backgroundColor: 'white',
          color: 'black',
          minWidth: '50px', // Square shape
          height: '50px', // Square shape
          borderRadius: '8px', // Slightly rounded edges to keep it square-ish
          '&:hover': {
            backgroundColor: '#f0f0f0', // Lighter gray on hover
          },
        }}
      >
        <SaveIcon />
      </Button>

      <Button
        variant="contained"
        onClick={toggleUserLocation}
        sx={{
          backgroundColor: 'white',
          color: 'black',
          minWidth: '50px', // Square shape
          height: '50px', // Square shape
          borderRadius: '8px', // Slightly rounded edges to keep it square-ish
          '&:hover': {
            backgroundColor: '#f0f0f0', // Lighter gray on hover
          },
        }}
      >
        {!userLocationOn ? <LocationDisabledIcon /> : <MyLocationIcon />}
      </Button>

      <Button
        variant="contained"
        onClick={toggleMarkerLock}
        sx={{
          backgroundColor: 'white',
          color: 'black',
          minWidth: '50px', // Square shape
          height: '50px', // Square shape
          borderRadius: '8px', // Slightly rounded edges to keep it square-ish
          '&:hover': {
            backgroundColor: '#f0f0f0', // Lighter gray on hover
          },
        }}
      >
  {lockMarkers ? <LockIcon /> : <LockOpenIcon />} {/* Update icon based on lockMarkersRef */}
        </Button>

        <Button
    variant="contained"
    onClick={handleDeleteSelectedMarker}
    sx={{
      backgroundColor: 'white',
      color: 'black',
      minWidth: '50px', // Square shape
      height: '50px', // Square shape
      borderRadius: '8px', // Slightly rounded edges to keep it square-ish
      '&:hover': {
        backgroundColor: '#f0f0f0', // Lighter gray on hover
      },
      display: lastDraggedMarkerRef.current && !lockMarkersRef.current ? 'block' : 'none', // Conditionally show the button
    }}
  >
    <DeleteIcon />
  </Button>
    </div>

    <Dialog
      open={showSaveOverlay}
      onClose={() => setShowSaveOverlay(false)}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        Export/Import Points
        <IconButton
          aria-label="close"
          onClick={() => setShowSaveOverlay(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

    {/* MUI Dialog for showing save/load data */}
    <DialogContent dividers>
    <div>
		<TabsComponent 
		  tabValue={tabValue}
		  setTabValue={setTabValue}
		  saveData={saveData}
		  fetchOnlineRecords={fetchOnlineRecords}
		  onlineRecords={onlineRecords}
		  handleLoadOnline={handleLoadOnline}
		  handleRemoveOnline={handleRemoveOnline}
		  handleSaveOnline={handleSaveOnline}
		  setShowSaveOverlay={setShowSaveOverlay}
		  jsonInput={jsonInput}
		  setJsonInput={setJsonInput}
		  handleLoad={handleLoad}
		  resetPoints={resetPoints}
		  haversineDistance={haversineDistance}
		  currentLocation={userLocationMarkerRef.current ? userLocationMarkerRef.current.getLngLat() : null} // Get current location from the marker
		/>
    </div>
    </DialogContent>

      <DialogActions>
        <Button onClick={() => setShowSaveOverlay(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>

    <div className="distance-container">
      <div>{totalDistance} km</div>
      {/*
      <div>{userSpeed} km/h</div>
      */}
      <div>{userHeading.toFixed(0)}° {userDirection}</div>
    </div>

  </div>
);

};

export default Map;